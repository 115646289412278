@import "mixins";

.container {
  margin: 0 auto;
  padding: 0 $gutter;
  max-width: ($max-width + 2 * $gutter);
}

.sm {
  max-width: 620px;

  @include desktop {
    margin: 60px auto;
  }
}
