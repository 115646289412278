@import "mixins";

.button {
  @include flex;
  @include transition;

  filter: grayscale(100%) opacity(75%);

  &:hover {
    filter: grayscale(0%) opacity(100%);
  }
}

.standalone {
  padding: 15px;
}

.text {
  font-size: 12px;
  font-weight: normal;
  margin-right: 10px;
  opacity: 0.4;
}
