@import "mixins";
@import "variables";

.success {
  color: $aqua;
}

.failure {
  color: $red;
}

.hash {
  color: $battleship;
  font-size: 12px;
  text-align: center;
}

.feedback {
  text-align: center;
  margin: 20px 0 40px;
}
