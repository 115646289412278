@import "mixins";
@import "variables";

.component {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.action {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.header {
  @include flex;
  margin-top: 10px;
  margin-bottom: 30px;

  &.to {
    margin-left: 20px;
  }
}

.heading {
  $font-size: 16px;
  color: white;
  font-size: $font-size;
  text-align: center;
  height: ($font-size * $line-height);

  &:empty {
    background: white;
    margin: 0 auto;
    opacity: 0.1;
    width: 50%;
  }
}

/* stats */
.stats {
  border-top: $border;
  padding-top: 20px;
  padding-bottom: 10px;
}

.dd {
  font-size: 16px;
  font-weight: normal;
}

.vertical {
  border-top: $border;
  padding: 20px;
}

.item {
  @include flex(space-between);
  font-size: 12px;
  margin-bottom: 5px;
}

.title {
  color: fade-out(white, 0.6);
  font-weight: 500;
}

.content {
  color: $light;
}

/* emphasize */
.emphasize {
  border-color: fade-out($blue, 0.75);
  box-shadow: 0 0 15px 0 fade-out($blue, 0.5);
}
