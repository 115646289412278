@import "mixins";
@import "variables";

.trigger {
  @include flex;
  color: $blue;
  border: 1px solid $blue;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: auto;
}

.dropdown {
  background-color: $darkblue;
  border-radius: $border-radius;
  border: $border;
  box-shadow: 0 10px 40px 0 fade-out(black, 0.7);
  padding: 10px 0;
}

.link {
  @include link;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 20px;
  text-align: left;
  text-transform: capitalize;
  width: 160px;
}
