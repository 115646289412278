.dot {
  animation: blink 1s infinite;
}

.component {
  $delay: 0.1s;

  .dot-1 {
    animation-delay: ($delay * 1);
  }

  .dot-2 {
    animation-delay: ($delay * 2);
  }

  .dot-3 {
    animation-delay: ($delay * 3);
  }
}

@keyframes blink {
  from {
    opacity: 0.25;
  }

  to {
    opacity: 0.5;
  }
}
