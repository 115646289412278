@import "variables";

.wrapper {
  position: relative;
}

.card {
  position: absolute;
  top: 100%;
  right: 0;

  margin-top: 10px;
  padding: 25px;

  background: $darkblue;
  border: 1px solid fade-out($blue, 0.75);
  border-radius: $border-radius;
  box-shadow: 0 20px 40px fade-out(black, 0.5);
}
