@import "mixins";

.modal {
  width: 540px;
  outline: 0;
}

.overlay {
  @include flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: fade-out(#070a13, 0.2);
  z-index: $zindex-modal-backdrop;
}
