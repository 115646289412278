@import "mixins";

.button {
  @include flex(space-between);
  @include transition;

  border-radius: 15px;
  background: #191d23;
  color: $aqua;
  font-size: 12px;
  padding: 5px 20px;

  svg {
    fill: $aqua;
  }

  &:hover {
    background: $aqua;
    color: $darkblue;
    text-decoration: none;

    svg {
      fill: $darkblue;
    }
  }

  & + & {
    margin-top: 10px;
  }
}

.delisted {
  opacity: 0.3;
}

.hidden {
  visibility: hidden;
}
