@import "mixins";

.toast {
  position: fixed;
  top: ($nav-height + $gutter);
  right: $gutter;
  z-index: 9999;

  background: $airdrop;
  border: 1px solid fade-out($blue, 0.5);
  border-radius: $border-radius;
  box-shadow: 0 10px 40px 0 fade-out(black, 0.7);
  padding: $gutter;
  text-align: center;
  width: 260px;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.image {
  animation: rotate 2s infinite;
  animation-timing-function: linear;
  transform-origin: bottom;
}

@keyframes rotate {
  25% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(0);
  }

  75% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0);
  }
}

.header {
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 2px;
}

.content {
  color: $gray;
  font-size: 12px;
  margin-bottom: $gutter;
}
