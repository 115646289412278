@import "mixins";

.header {
  @include flex;
  margin-bottom: 30px;
  padding-left: 24px;
}

.title {
  flex: 1;
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.close {
  @include link;
  @include flex;
}

.item {
  & + & {
    margin-top: 20px;
  }
}

.button {
  @include flex;
  @include transition(border-color);

  background: $bg;
  border-color: $bg;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
  width: 100%;

  &:hover {
    border-color: $aqua;
  }
}

.label {
  flex: 1;
  text-align: left;
}
