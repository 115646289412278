@import "mixins";
@import "variables";

.button {
  @include flex;
  @include button;
  @include transition(background-color);

  background-color: transparent;
  border-radius: 17px;
  border-width: 2px;
  border-style: solid;
  border-color: $blue;
  color: white;
  font-size: 13px;
  font-weight: 600;
  height: 34px;
  padding: 0 20px;

  &:hover {
    background-color: fade-out($blue, 0.9);
    text-decoration: none;
  }

  i {
    margin-right: 5px;
  }
}

.address {
  font-size: 12px;
  font-weight: normal;
  text-transform: none;
}
