@import "mixins";
@import "variables";

.wallet {
  width: 260px;

  i {
    margin-right: 5px;
  }
}

.header {
  @include flex(space-between);
  margin-bottom: 30px;
}

.address {
  @include flex;

  color: white;
  font-size: 16px;
}

.disconnect {
  font-size: 10px;
}

.actions {
  @include flex(space-between);
  font-size: 12px;

  .button {
    @include flex;
    @include link($blue, lighten($blue, 25%));
    text-decoration: none;
  }

  i {
    font-size: 14px;
  }
}

.send {
  margin-top: 20px;
}
