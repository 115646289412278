@import "mixins";
@import "variables";

.header {
  background: $header;
  box-shadow: 0 10px 10px 0 fade-out($bg, 0.5);
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;
}

@include mobile {
  .header {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .collapsed {
    position: sticky;
  }
}

.container {
  @include desktop {
    @include flex(space-between);
  }
}

.wrapper {
  @include flex(space-between);
}

.logo {
  @include flex;
  height: $nav-height;
}

.badge {
  margin-left: 8px;
}

.toggle {
  @include flex;

  @include desktop {
    display: none;
  }
}

.support {
  @include desktop {
    @include flex;
  }

  @include mobile {
    .collapsed & {
      display: none;
    }
  }
}

.connect {
  @include flex;
  padding-left: 15px;

  @include mobile {
    display: none;
  }
}

.hr {
  margin: 0;
}
