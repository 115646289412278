@import "mixins";

.wrapper {
  @include flex(flex-start);

  .title {
    font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
  }
}

.center {
  .title {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
  }
}

.title {
  color: white;
}
