@use "sass:math";
@import "mixins";

.badge {
  $height: 21px;
  @include flex;
  display: inline-flex;
  height: $height;
  border-radius: math.div($height, 2);
  background: fade-out(white, 0.9);
  color: white;
  font-size: 10px;
  font-weight: 500;
  min-width: 90px;
}
