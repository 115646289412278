@import "mixins";
@import "variables";

.list {
  background: saturate(darken($darkblue, 2%), 6%);
  border-radius: $border-radius;
  font-size: 12px;
  padding: 20px;

  &:not(:first-child) {
    margin-top: 30px;
  }
}

.item:not(:first-child) {
  margin-top: 10px;
}

.article {
  @include flex(space-between);
}

.title {
  color: $battleship;
}

.content {
  color: $light;
}
