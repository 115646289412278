@import "mixins";
@import "variables";

.group + .group {
  margin-top: 20px;
}

.component {
  input,
  textarea {
    width: 100%;
  }

  ::placeholder {
    color: fade-out(#f0f0f0, 0.8);
  }
}

$padding: 10px 20px;

.border {
  @include transition(border-color);
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: fade-out($slate, 0.5);
  padding: $padding;
  position: relative;

  &.readOnly {
    $bg: fade-out($slate, 0.9);
    border-color: $bg;
    background: $bg;
  }

  &.focused {
    border-color: $blue;
  }

  &.error {
    border-color: $red;
  }
}

.select > button {
  @include flex(space-between);
  width: 100%;
}

/* field */
.header,
.wrapper {
  @include flex(space-between);

  .label,
  .field {
    flex: 1;
  }

  .help,
  .unit {
    flex: none;
    text-align: right;
    @include flex(flex-end);
  }
}

.header {
  color: $battleship;
  font-size: 12px;
  margin-bottom: 10px;
}

.wrapper {
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
}

.label > label {
  display: block;
  font-weight: 600;
}

.help {
  white-space: pre;
}

.max {
  margin-right: 10px;
}

/* assets */
.assets {
  margin-top: 10px;
}

/* feedback */
.feedback {
  color: $red;
  font-size: 12px;
  margin-top: 10px;
}
