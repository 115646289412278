@import "mixins";
@import "variables";

$radius: 10px;

.tabs {
  @include flex;
}

.tab {
  @include flex;
  @include link;

  flex: 1;

  font-size: 18px;
  font-weight: 500;
  height: 60px;
  text-transform: capitalize;

  &:only-child {
    height: unset;
    margin-top: 30px;
  }

  &.active {
    border-bottom: 1px solid $darkblue;
  }

  &:not(.active) {
    background: saturate(darken($darkblue, 2%), 6%);
    border-bottom: 1px solid $bg;
  }

  & + & {
    border-left: 1px solid $bg;
  }
}

.content {
  padding: 30px 25px;
}
