@import "mixins";

.connected {
  @include flex(center, stretch);
  border-width: 1px;

  > * {
    @include flex;
  }
}

.balance {
  border-left: 1px solid fade-out($blue, 0.9);
  color: $blue;
  margin-left: 10px;
  padding-left: 10px;
}
