@import "mixins";
@import "variables";

.images {
  @include flex;

  .fill,
  .outline {
    @include flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .fill {
    z-index: 1;
  }

  .outline {
    border: 1px solid $battleship;
    margin-left: -10px;
    z-index: 0;
  }
}

.text {
  font-size: 11px;
}
