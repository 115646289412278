$max-width: 1080px;
$nav-height: 80px;

$bg: #211f24;
$darkblue: #17141a;
$blue: $aqua;
$airdrop: #1e3138;
$button: $darkblue;
$header: $bg;
$card-padding-main: 25px;
