@import "mixins";

.component {
  background-color: $darkblue;
  border-radius: 10px;

  padding: 25px;
  margin-bottom: $gutter;
}

.uniswap {
  fill: #ff007b;
}
