@use "sass:math";
@import "mixins";

$gutter: 30px;
$margin: math.div($gutter, 2);

.row {
  @include flex(flex-start);
  align-items: stretch;
  margin: -$margin;

  > * {
    flex: 1;
    margin: $margin;
  }

  @include mobile {
    flex-direction: column;
  }

  & + & {
    margin-top: $margin;
  }
}

@include desktop {
  .wrap {
    flex-wrap: wrap;

    > * {
      flex: none;
    }
  }

  .wrap-2 > * {
    width: calc((100% / 2) - #{$gutter});
  }

  .wrap-3 > * {
    width: calc((100% / 3) - #{$gutter});
  }

  .wrap-4 > * {
    width: calc((100% / 4) - #{$gutter});
  }
}
