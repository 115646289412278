@import "mixins";

.component {
  margin-bottom: 30px;

  @include desktop {
    @include flex(space-between);
    padding: 0 90px;
  }
}

.main {
  flex: 1;
  max-width: 360px;
}

.heading {
  color: $light;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 5px;
}

.encourage {
  color: darken(white, 40%);
  font-size: 16px;
  margin-bottom: 15px;
}

.summary {
  @include flex;
  margin-top: 40px;

  .item {
    flex: 1;
  }

  .title {
    color: darken(white, 60%);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 1px;
  }

  .content {
    color: $light;
    font-size: 16px;
  }
}

.image {
  flex: none;
  overflow: hidden;

  @include mobile {
    margin-top: $gutter;
  }
}
