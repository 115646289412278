@import "mixins";

.link {
  @include flex;
  @include transition(color);
  display: inline-flex;
  color: $gray;
  font-size: 14px;
  font-weight: 500;

  svg {
    @include transition(fill);
    fill: $gray;
    margin-right: 4px;
  }

  &:hover {
    color: white;
    text-decoration: none;

    svg {
      fill: white;
    }
  }
}
