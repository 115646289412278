@import "mixins";
@import "variables";

$padding: 15px;

.menu {
  @include desktop {
    @include flex;
  }
}

.link {
  @include link;
  display: block;
  font-weight: 500;

  @include desktop {
    padding: 0 $padding;
    line-height: $nav-height;
  }

  @include mobile {
    font-size: 24px;
    line-height: 2;
  }
}

.item {
  .submenu {
    display: none;
  }

  &:hover .submenu {
    display: block;
  }
}
